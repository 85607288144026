<template>
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 6L9 8L12 6"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.75 9H5M1 7H3.66667"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M3.33337 5.00004V4.66671C3.33337 4.31309 3.47385 3.97395 3.7239 3.7239C3.97395 3.47385 4.31309 3.33337 4.66671 3.33337H13.3334C13.687 3.33337 14.0261 3.47385 14.2762 3.7239C14.5262 3.97395 14.6667 4.31309 14.6667 4.66671V11.3334C14.6667 11.687 14.5262 12.0261 14.2762 12.2762C14.0261 12.5262 13.687 12.6667 13.3334 12.6667H4.66671C4.31309 12.6667 3.97395 12.5262 3.7239 12.2762C3.47385 12.0261 3.33337 11.687 3.33337 11.3334V11" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
  </svg>
</template>
